.register {
  background-color: #fef2ef;
}

.register-title {
  font-family: "Poppins";
  font-size: 48px;
  font-weight: bold;
  color: #404757;
}

.atention-title {
  color: #404757;
}

.register-buttom {
  color: white;
  padding: 8px 16px;
  border: none !important;
  border-radius: 2.5rem !important;
  font-family: "Poppins" !important;
  font-weight: bold !important;
  font-size: 16px !important;
}

.register-buttom:enabled {
  background: transparent linear-gradient(180deg, #1186FA 0%, #1166FB 100%) 0% 0% no-repeat padding-box;;
}

.country-card {
  max-width: 12rem !important;
  border-radius: 1rem !important;
  max-height: 10rem !important;
}

.register-buttom:disabled {
  background-color: #858a96 !important;
  color: white;
  padding: 8px 16px;
  border-radius: 1.5rem;
  font-family: "Poppins";
  font-weight: bold;
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .register {
    background-color: #000000;
    padding: 10px;
  }

  .register-title {
    color: #999999;
    font-size: 20px;
    font-weight: bold;
  }

  .atention-title {
    color: #ffffff;
    font-size: 20px;
  }

  .country-card {
    max-width: 7rem !important;
    border-radius: 0.5rem !important;
    max-height: 7rem !important;
    padding: 0 !important;
  }
}
