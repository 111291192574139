.form-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    align-items: center;
}

.phone-field{
    grid-column: 1/3;
}
.email-field{
    grid-column: 1/3;
}
.identication-label {
    width: 100%;
}

@media screen and (max-width: 1054px) {
    
    .identication-label {
        width: 107%;
    }
}
@media screen and (max-width: 992px) {
    
    .identication-label {
        width: 100%;
    }
}