.wlcm-page-two {
  background: transparent linear-gradient(180deg, #eca109 0%, #ed8e09 100%) 0%
    0% no-repeat padding-box;
  border: 1px solid #707070;
  height: 100%;

  .wlcm-two-wrp {
    padding-top: 30px;
    padding-left: 72px;
    .point-section {
      margin-top: 30px;

      ul {
        margin-left: 25px;
        padding: 0;

        li {
          list-style: auto;
          font-size: 22px;
          color: #fff;
        }
      }
    }

    .title {
      p {
        font-size: 50px;
        line-height: 57px;
        color: #fff;
        margin-top: 30px;
        font: normal normal 900 80px/88px Avenir;
        letter-spacing: -1.93px;
        color: #ffffff;
        text-shadow: 0px 3px 6px #00000029;
        margin-bottom: 0.5rem;
      }
    }

    .sub-title {
      p {
        margin: 0;
        color: #fff;
        font-size: 30px;
        font: normal normal medium 48px/56px Avenir;
        letter-spacing: -1.16px;
        color: #ffffff;
        text-shadow: 0px 3px 6px #00000029;
      }
    }

    .btn-div {
      button {
        font-size: 16px !important;
        background: linear-gradient(180deg, #b924b4, #8525b7) !important;
        border: none;
        color: #fff;
        height: 53px;
        width: 154px;
      }
    }
  }

  .side-img {
    text-align: center;

    img {
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      // object-fit: contain;
    }
  }
}

@media (max-width: 1199px) {
  .wlcm-page-two .side-img img {
    height: auto;
  }
}

@media (max-width: 991px) {
  .wlcm-page-two .wlcm-wrp .title p {
    font-size: 40px;
  }

  .wlcm-page-two .wlcm-wrp .sub-title p {
    font-size: 18px;
  }

  .wlcm-page-two .wlcm-wrp .point-section ul li {
    font-size: 14px;
  }

  .wlcm-page-two .side-img img {
    object-fit: cover !important;
  }
}

@media (max-width: 767px) {
  .wlcm-page-two {
    height: 100% !important;
  }

  .wlcm-page-two .side-img img {
    object-fit: cover;
    height: 100% !important;
  }

  .side-img {
    position: absolute;
    bottom: 0;
  }

  .wlcm-page-two {
    height: 100% !important;
  }

  .wlcm-two-wrp {
    text-align: left;
  }

  .side-img {
    position: inherit !important;
  }
}

@media (max-width: 575px) {
  .side-img {
    position: inherit !important;
    bottom: 0;
  }

  .wlcm-page-two {
    height: 100% !important;
  }

  .wlcm-page-two .wlcm-two-wrp .title p {
    font-size: 28px;
    line-height: 38px;
    margin-top: 15px;
    margin-bottom: 0;
  }

  .wlcm-page-two .wlcm-two-wrp .sub-title p {
    font-size: 24px;
  }

  .wlcm-page-two .wlcm-two-wrp .btn-div button {
    margin-top: 15px;
  }
}
