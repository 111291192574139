.prize-setup-one {
  .prize-wrp-page {
    display: flex;
    align-items: baseline;

    .left-side-content {
      width: calc(100% - 400px);
      margin-left: auto !important;
      background: #f7f7f7;
      padding: 0px 50px;
      padding-top: 20px;
      padding-left: 140px !important;

      .award-section {
        .award-title {
          color: #dea01a;
          letter-spacing: 1px;
          margin: 0;
          padding: 0;
          font: normal normal 900 28px/56px "Avenir";
          color: #dea01a;
          text-transform: uppercase;
        }

        .award-text {
          max-width: 970px;
          font: normal normal medium 48px/56px "Avenir";
          letter-spacing: -1.16px;
          font-size: 48px;
          line-height: 48px;
          color: #454847;
        }
      }

      .content-heading {
        h3 {
          font-size: 24px;
          color: #454847;
          font-weight: bolder;
          margin-left: 61px;
        }
      }

      .checkbox-section {
        margin-left: 13px;
      }
    }

    .active {
      .add-award-content {
        opacity: 0.4;
        pointer-events: none;
      }
    }

    .add-award-content {
      margin-left: 5px;
      margin-top: 40px;

      .add-award-text {
        font: normal normal medium 24px/33px Avenir;
        letter-spacing: -0.58px;
        color: #454847;
        font-size: 22px;
      }

      .input_section {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
      }

      input.form-control {
        max-width: 370px;
      }

      .input_section button {
        background: #4cd964;
        border: #4cd964;
      }
    }

    .option-section {
      .info-text {
        img {
          width: 20px !important;
          height: 20px !important;
          margin-bottom: 5px !important;
        }

        font-size: 14px;
        margin-top: 35px;
        font: normal normal normal 16px/22px "Avenir";
        letter-spacing: 0px;
        color: #454847;
        font-weight: 100;
      }
    }

    .footer-buttons {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;

      .back_button {
        background: #e2f0fe;
        border: #e2f0fe;
        color: #a204de !important;
        padding: 6px 20px 8px 20px;
        line-height: 0;
        font-weight: 600;

        img {
          margin-right: 8px;
          vertical-align: baseline;
          position: relative;
          top: 2px;
        }
      }

      .btn-active {
        background: linear-gradient(180deg, #b924b4, #8525b7) !important;
        border: none;
      }

      .foward_button {
        background: #d2d3d4;
        border: #d2d3d4;
        color: #f7f7f7;
        padding: 12px 21px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
      }
    }
  }

  .checkbox-listing {
    align-items: flex-start !important;

    input {
      width: 20px;
      height: 20px;
      position: relative;

      &:checked {
        background-color: transparent;
        border-color: #eca109;
        background-image: url(../../../images/logo/check-bold.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: auto;

        + {
          label {
            color: #a204de;
          }
        }
      }

      &:focus {
        box-shadow: none;
      }
    }

    label {
      font-size: 18px;
    }
  }
}

.ul-nod {
  list-style-type: none;
}

.golden-li {
}

.golden-li::before {
  content: "\2022";
  color: #dea01a;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  font-size: 1.3em;
  margin-left: -1em;
}

.generic-examples * {
  color: #454847;
}

.generic-prize-info-img {
  width: 70%;
  text-align: center;
}

.prize-setup-one .checkbox-listing label {
  font: normal normal 700 18px/24px "Avenir" !important;
  letter-spacing: -0.77px !important;
  color: #454847;
}

@media (max-width: 1199px) {
  .prize-setup-one .prize-wrp-page .left-side-content {
    padding: 20px;
  }
  .left-side-content {
    padding: 0 !important;
    width: calc(100% - 300px) !important;
  }
}

@media (max-width: 991px) {
  .prize-setup-one .prize-wrp-page .left-side-content {
    padding: 0 0 0 2em !important;
  }

  .side-panel .prize-setup-one-img img {
    margin-top: 1rem;
    width: 419px;
    height: 297px;
  }
}

@media (max-width: 767px) {
  .prize-setup-one .prize-wrp-page {
    display: block;
  }

  .prize-setup-one .prize-wrp-page .left-side-content {
    width: 100% !important;
  }
  .prize-wrp-page {
    div div div {
      div img {
        text-align: center;
      }
      div div {
        width: 300px;
        margin: auto;
      }
    }
  }
  .side-panel .prize-setup-one-img {
    padding-bottom: 20px;
  }

  .prize-setup-one .prize-wrp-page .add-award-content .input_section {
    display: block;
  }

  .prize-setup-one .prize-wrp-page .add-award-content .input_section button {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .checkbox-listing {
    padding-bottom: 10px !important;
  }

  .prize-setup-two .prize-wrp-page .add-award-content {
    margin-left: 0 !important;
  }

  .btn-add {
    width: auto !important;
  }
}

@media (max-width: 575px) {
}
