.plan-list {
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 500;
  color: #404757;
  margin-right: 2rem;
  text-indent: -24px;
  padding-left: 24px;
}

.plan-title {
  font-size: 72px;
  font-family: "Poppins";
  font-weight: bold;
  color: #404757;
}

.plan-rowitem-title {
  font-family: "Poppins";
  font-size: 24px;
  font-weight: bold;
  color: #404757;
}

.plan-rowitem-desc {
  font-family: "Open Sans";
  font-size: 14px;
  color: #404757;
  font-weight: 300;
}

.plan-rowitem-img {
  width: 4rem;
  height: 4rem;
}

.plan-payment-title {
  font-family: "Poppins";
  color: #404757;
  font-size: 32px;
  font-weight: bold;
}

.plan-payment-desc {
  font-family: "Open Sans";
  font-size: 10px;
  color: #858a96;
}

.plan-left-pane {
  border-bottom-right-radius: 10%;
  background-color: #ffffff;
  height: 100%;
}

.movil-title-one {
  font: bold 32px Poppins !important;
  color: #999999;
}

.movil-title-two {
  font: bold 32px Poppins !important;
  color: #000;
}

.button-continue {
  background-color: #1186fa !important;
  color: white;
  border: none !important;
  border-radius: 30px !important;
  cursor: pointer;
  font-family: Poppins;
  font-size: 18px !important;
  height: 60px;
}

@media screen and (max-width: 768px) {
  .plan-rowitem-title {
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: #000;
  }

  .plan-payment-title {
    font-family: "Poppins";
    color: #000;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }

  .plan-payment-desc {
    text-align: center !important;
  }
}
