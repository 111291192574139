* {
  margin: 0;
}

.left-side {
  height: 100%;
  background-color: #fff;
  border-top-right-radius: 83px;
  border-bottom-right-radius: 83px;
}

.right-side {
  width: 100%;
  height: 100%;
}

.bg-color-base {
  background-color: #fef2fa;
}



.button {
  background-image: linear-gradient(to top, #b924b4, #b924b4) !important;
  color: white;
  border: none !important;
  border-radius: 30px !important;
  cursor: pointer;
  font-family: Poppins;
  font-size: 18px !important;
  height: 60px;
}

.laststep-button {
  margin-top: 40px;
  width: 250px;
  height: 55px;
  background: transparent linear-gradient(180deg, #1186FA 0%, #1166FB 100%) 0% 0% no-repeat padding-box;
}

@media screen and (max-width: 768px) {
  .bg-color-base {
    background-color: #000;
  }

  .left-side {
    height: 100% !important;
    background-color: #000 !important;
    margin-left: 0;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .finish-up-page-responsive {
    height: 100% !important;
    background: transparent linear-gradient(180deg, #8525b7 0%, #b924b4 100%) 0%
      0% no-repeat padding-box;
  }

  .responsive-text-header {
    font-size: 16px;
    font-family: Poppins;
    color: #fff;
  }

  .responsive-text-title {
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
  }

  .responsive-finish-button {
    background-image: linear-gradient(to top, #eca109, #ed8e09) !important;
    color: white;
    border: none !important;
    border-radius: 30px !important;
    cursor: pointer;
    font-family: Poppins;
    font-size: 16px !important;
    height: 60px;
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .bottom-movil-detail {
    min-height: 1rem !important;
    background-color: #ffffff !important;
    position: fixed !important;
  }
  .laststep-button {
    background: transparent linear-gradient(180deg, #1186FA 0%, #1166FB 100%) 0% 0% no-repeat padding-box;
    color: white;
    border: none !important;
    border-radius: 30px !important;
    cursor: pointer;
    font-family: Poppins;
    font-size: 18px !important;
    width: 250px;
    height: 55px;
    margin: 0;
  }



}
