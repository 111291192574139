$button-color: magenta;

.input-group {
  width: 100%;
  display: inline-block;
  position: relative;

  label {
    margin: 8px 0;
  }

  input {
    width: 100%;
    border-radius: 5px;
  }

  svg {
    size: 1rem;
    position: absolute;
    right: 10px;
    bottom: 20%;
  }
}

.multi-input {
  margin-top: 20px;
  width: 100%;
  display: flex;
  gap: 10px;
}

small {
  color: red;
  font-size: 10px;
  display: block;
}

.poppins-family {
  font-family: "Poppins";
}

.card-ui-component-btn-confirm {
  background: linear-gradient(180deg, #b924b4, #8525b7) !important;
}

.logo-image {
  max-width: 100%;
  max-height: 100px;
}

.custom-checkbox input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #8525b7;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
}

.custom-checkbox input[type="checkbox"]:checked {
  //background-color: #FF8264;
  background-image: -webkit-linear-gradient(#8525b7, #b924b4);
  background-image: linear-gradient(#8525b7, #b924b4);
}

.custom-checkbox input[type="checkbox"]::before {
  content: "";
  display: block;
  width: 10px;
  height: 5px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: scaleX(-1) rotate(45deg);
  position: absolute;
  margin: auto 3px;
  top: 6px;
  transition: transform 0.3s ease;
  visibility: hidden;
}

.custom-checkbox input[type="checkbox"]:checked::before {
  visibility: visible;
}

.payment-method-input {
  font-family: "Open Sans";
  height: 3.4rem;
  border-radius: 1.5rem !important;
  border: 2px solid #707070 !important;
}

.payment-method-input::placeholder {
  color: #858a96;
  font-size: 16px;
  font-family: "Open Sans";
  font-weight: bold;
}

.payment-btn-container {
  display: flex;
  justify-content: space-between;
}

.payment-form-btn {
  color: white;
  border: none !important;
  border-radius: 52px !important;
  font-family: "Poppins" !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  width: 210px;
  height: 48px;
  display: flex;
  justify-content: center;
  gap: 40px;
  align-items: center;
}

.no-payment-btn {
  border: none;
  background-color: transparent;
  text-decoration: underline;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.coupon-header{ 
  display: flex;
  justify-content: space-between;
  padding: 10px 0;

  img {
    width: 15px;
  }
}

.dropdown-btn {
  background-color: transparent;
  border: none;
  rotate: 180deg;
}

.dropdown-close {
  rotate: -90deg;
  background-color: transparent;
  border: none;
}


.show-coupon {
  display: flex;
}
.hide-coupon {
  display: none;
}


.coupon-controls-control {
  padding: 20px 0;
}

.coupon-input {
  position: relative;
}

.cupon-code-btn {
  position: absolute !important;
  right: -1px;
  top: 78px;
  color: white;
  width: 100%;
  padding: 0.5rem 0;
  max-width: 8rem !important;
  min-height: 3.5rem !important;
  border: none !important;
  border-radius: 2.5rem !important;
  font-family: "Poppins" !important;
  font-weight: bold !important;
  font-size: 1.2rem !important;
  background-color: #858A96 !important;
}

.cupon-code-bt:hover {
  background-color: lighten( #858A96, .10);
  font-weight: 600 !important;
  font-size: 20px !important;
}

.cupon-code-btn:enabled {
  background-color: #1186fa;
}

.payment-form-btn:enabled {
  background: transparent linear-gradient(180deg, #1186FA 0%, #1166FB 100%) 0% 0% no-repeat padding-box;

}

.payment-form-btn:disabled {
  background-color: #858a96 !important;
  color: white;
  width: 100%;
  max-width: 20rem;
  min-height: 4rem;
  border-radius: 1.5rem;
  font-family: "Poppins";
  font-weight: bold;
  font-size: 24px;
}

.plan-securely {
  color: #858a96;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: bold;
  margin: 0 1px 0 9px !important;

}

.plan-securely-ssl-wrapper {
  border: 1px solid #858a96;
  display: flex;
  align-items: center;
  padding: 4px 10px;
}

.span-free-membership {
  color: #eca109;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
}

.label-price-details {
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: bolder;
}

.pay-options-col{
  margin: 0 0 16px 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}

.pay-debit-credit {
  border-radius: 5px;
  padding: 5px 10px;
  border: 1px solid #858A96;

  i {
    margin-right: 10px;
  }
}
.pay-nequi {
  border-radius: 5px;
  padding: 5px 50px;
  border: 1px solid #858A96;
}
.pay-davi {
  border-radius: 5px;
  padding: 8px;
  border: 1px solid #858A96;

  img {
    height: 50px;
    width: 50px;
    object-fit: contain;
  }
}
.pay-pse {
  border-radius: 5px;
  padding: 5px 50px;
  border: 1px solid #858A96;

  img {
    height: 30px;
    width: 50px;
    object-fit: contain;
  }

}
.payment-active{
  border: 3px solid #1186fa;
}

.payment-no-active {
  background-color: #FFFFFF;
  opacity: .4;
}


@media screen and (max-width: 1215px) {

.pay-options-col {
  gap: 16px;
}

.pay-nequi {
  padding: 5px 20px;
}
.pay-davi {
  padding: 5px 20px;
}

}

@media screen and (max-width: 992px) {
.pay-options-col {
  gap: 16px;
}

.pay-nequi {
  padding: 5px 50px;
}
.pay-davi {
  padding: 5px 50px;
}

.cupon-code-btn {
  right: 0;
  top: 77px;

}

}

@media screen and (max-width: 768px) {

  .pay-options-col {
    gap: 16px;
  }

  .cupon-code-btn {
    margin-top: 1.5rem;
  }

  .plan-securely {
    color: #858a96;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: bold;
  }

  .cupon-code-btn {
    margin: 0;
  }

  .payment-form-btn {
    gap: 25px;
  }

  .payment-form-btn {
    color: white;
    border: none !important;
    border-radius: 52px !important;
    font-family: "Poppins" !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: center;
    gap: 40px;
    align-items: center;
  }

}

@media screen and (max-width: 430px) {
  .pay-options-col{
    margin: 0 0 16px 0;
    gap: 16px;

    .pay-debit-credit {
      border-radius: 5px;
      padding: 5px 5px;
      border: 1px solid #858A96;
    
      i {
        margin-right: 10px;
      }
    }
    .pay-nequi {
      border-radius: 5px;
      padding: 5px 15px;
      border: 1px solid #858A96;
    }
    .pay-davi {
      border-radius: 5px;
      padding: 5px 15px;
      border: 1px solid #858A96;
    
      img {
        height: 30px;
        width: 50px;
        object-fit: cover;
      }
    }
  }

  .cupon-code-btn {
    right: 0;
    top: 77px;

  }
  
}

