@font-face {
  font-family: "Avenir-Regular";
  src: url("../../../fonts/Avenir-Regular/Avenir-Regular.woff") format("woff");
}

body {
  overflow-x: hidden;
  font-family: "Avenir-Regular";
}

.register_form {
  background: white;

  .main_div {
    max-width: 100%;
    margin: 0 !important;
    padding: 0 !important;
  }

  .bee_img.row {
    margin-left: 17px !important;
  }

  .overflow-hidden.card {
    background: #f7f7f7;
    min-height: 832px;
  }

  .form_side_img {
    height: auto !important;
    width: 90%;
    margin: 0 auto;
    object-fit: cover;
  }
}

.select2-selection__control--is-focused {
  box-shadow: none !important;
}

@media (max-width: 1199px) {
  .register_form .overflow-hidden.card {
    min-height: auto !important;
  }
}

@media (max-width: 991px) {
  .register_form .form_side_img.p-0 img {
    height: auto !important;
    width: 100%;
    object-fit: cover;
  }
}

@media (max-width: 767px) {
  .register_form .form_side_img.p-0 img {
    height: auto !important;
    width: 100%;
    object-fit: cover;
  }

  .select-wrp-mob {
    margin-bottom: 20px;
  }

  .p-none-mob {
    padding: 0 !important;
  }
}

@media (max-width: 575px) {
  .register_form .form_side_img.p-0 img {
    height: auto !important;
    width: 100%;
    object-fit: cover;
  }
}
