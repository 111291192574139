.text-header {
  font-weight: 700;
  font-size: 10px;
  font-family: "Open Sans";
}

/* Tablet Devices */

@media (min-width: 767px) and (max-width: 1023px) {
  .text-header {
    font-size: 16px;
  }
}

/* Desktop Devices */

@media (min-width: 1023px) {
  .text-header {
    font-size: 16px;
  }
}
