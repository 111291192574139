.title-col-2 {
  font-size: 50px;
  font-weight: 700;
  font-family: Poppins;
  color: #404757;
  text-align: center;
}

.base-circle {
  text-align: center;
  font-size: 20px;
  font-family: Avenir;
  font-weight: bold;
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.active-circle {
  background: transparent linear-gradient(180deg, #ECA109 0%, #ED8E09 100%) 0% 0% no-repeat padding-box;
}
.secondary-circle {
  background: #D2D3D4 0% 0% no-repeat padding-box;
}

.title-steps {
  font-size: 20x;
  font-weight: bold;
  font-family: Poppins;
  color: #404757;
  margin-bottom: 0;
}

.subtitle-steps {
  margin-top: 0;
  font-size: 12px;
  font-family: Poppins;
  color: #404757;
}

.laststep-button {
  background-color: #1186fa;
  color: white;
  border: none !important;
  border-radius: 30px !important;
  cursor: pointer;
  font-family: Poppins;
  font-size: 18px !important;
  padding: 8px 16px;
}


