.prize-card {
  margin: auto;
  height: 324px;
  width: 600px;
  border: none !important;
  border-radius: 25px !important;
}

.prize-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.prize-img {
  margin-top: 50px;
  width: 85%;
  height: 85%;
  object-fit: cover;
}

.card-title {
  font: 18px bold "Nunito Sans";
}

.card-icon {
  height: 35%;
  width: 15%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-icon-with-gift {
  height: 40%;
  width: 16%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.border-bronce {
  border: 2px solid rgba(255, 255, 255, 0.5);
  color: rgba(255, 255, 255, 0.5);
}

.border-silver {
  border: 2px solid rgba(0, 0, 0, 0.5);
  color: rgba(0, 0, 0, 0.5);
}

.border-gold {
  border: none;
  color: rgba(0, 0, 0, 0.5);
}

.active-card-icon {
  height: 95px;
  width: 105px;
  background: #fff;
  border: 2px solid #117efa;
}

.seal-icon {
  font-size: 16px !important;
  font-weight: bolder !important;
  font-family: 'Nunito Sans' !important;
}

.seal-icon-X {
  font-size: 32px !important;
  font-weight: normal !important;
  font-family: 'Nunito Sans' !important;
}

.gift-seal {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gift-seal-noprize {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.5;
}

.seal-redeemed {
  background: rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 768px) {
  .prize-card {
    margin: auto;
    height: 100%;
    width: 95%;
    border: none !important;
    border-radius: 25px !important;
  }

  .card-icon {
    height: 25%;
    width: 13%;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .active-card-icon {
    height: 50px;
    width: 60px;
    background: #fff;
    border: 2px solid #117efa;
  }

  .prize-img {
    margin-top: 0;
    width: 85%;
    height: 85%;
  }
}
