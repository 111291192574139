.profile-details {
  margin: 0;
  padding: 0;

  .main-wrp-page {
    display: flex;
    align-items: baseline;

    .left-side-content {
      width: 100%;
      margin-left: auto !important;
      background: #f7f7f7;
      padding: 0px 30px;
      padding-top: 20px;
      padding-left: calc(0.8em + 1vw) !important;

      .content-heading {
        h1 {
          color: #454847;
          margin-left: 61px;
          font-size: 3em;
          font-family: Nunito Sans;
          font-weight: bold;
        }
      }
    }
  }
  .card-prize__container {
    padding: 0 calc(0.1em + 1vw);
  }
  .profile {
    font-weight: bolder !important;
  }

  .personalize-profile-section {
    padding-left: 10px !important;

    .profile {
      font-size: 14px;
      color: #454847;
      font-size: 23px;
      font-weight: 400;
      padding-bottom: 5px !important;
      margin-left: 26px !important;
    }

    .personalize-section {
      margin-left: 37px;

      .attach {
        font-weight: 500;
        font-size: 16px;

        p {
          font-weight: 500;
          font-size: 16px;
        }

        .upload-btn {
          background: #1186fa;
          margin-top: 10px;
          border: none !important;
        }

        .profile-color-btn {
          width: 100px;
          height: 29px;
          display: flex;
          background: orange !important;
          border: 0.55px solid #707070 !important;
        }

        .text-color-btn {
          width: 100px;
          height: 29px;
          display: flex;
          background: #fff !important;
          border: 1px solid #707070 !important;
        }

        .second {
          font-size: 12px;
          font-weight: 100;
        }

        .store-div {
          position: relative;
          display: table-caption;
          margin-top: 21px;
        }

        .store-logo {
          width: 120px;
          height: 120px;
          border-radius: 16px !important;
          object-fit: contain;
          box-shadow: 0px 3px 6px #7f7f7f6b;
          margin-bottom: 27px;
        }

        .remove-icon {
          position: absolute;
          top: -8px;
          right: -8px;
        }

        .change-logo {
          font-size: 16px;
          color: #0075f0;
        }
      }
    }

    .preview_section {
      .bee-bussiness {
        display: flex;
        align-items: flex-start;
        gap: 15px;
        margin-left: 40px;

        .beepara {
          margin: 0px !important;
          font-size: 15px;
          color: #454847;
          margin-top: 10px !important;
          font-weight: 100 !important;
        }

        .beepara_second {
          font-size: 1.2em;
          margin: 0px !important;
          font-weight: 600 !important;
          font: normal normal medium 20px/27px Avenir;
          letter-spacing: -0.48px;
        }

        .barcode {
          padding-top: 5px;
          width: 75px;
        }

        .phone-img {
          width: 230px;
        }

        .mobile-div {
          position: relative;

          .phone-img-2 {
            width: 100%;
            position: absolute;
            bottom: 46px;
            left: 39px;
            border-radius: 0 0 15px 15px;
          }

          p.phone-details-heading {
            font-size: 9px;
            width: 60px;
            margin: 0;
          }

          p.phone-details-heading span {
            font-weight: 800;
          }

          span.phone-details-miam {
            font-size: 8px;
            color: #ffa207;
            display: flex;
            align-items: center;
          }

          .phone-under-div {
            position: absolute;
            top: 50px;
            left: 45px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 60%;

            .phone-img-img {
              width: 40px;
              height: 40px;
              flex: 0 0 40px;

              img {
                width: 100%;
                height: 100%;
                border-radius: 8px;
                object-fit: contain;
                box-shadow: 0px 3px 6px rgb(127 127 127 / 42%);
              }
            }
          }

          p.phone-details-low {
            font-size: 7px;
            color: gray;
            margin: 0;
            line-height: 3px;
          }

          .phone-button {
            font-size: 7px;
            color: #fff;
            text-align: center;
            background: #1186fa;
            border: none;
            border-radius: 3px;
          }
        }
      }

      .prev-heading {
        margin: 15px 0 10px 23px !important;
        font-size: 20px;
        color: #454847;
      }

      .line_button_section {
        margin-top: 27px;
        margin-bottom: 15px;

        .line_section {
          border: 1px solid #70707047;
          margin-left: 40px;
        }

        .line_button {
          text-align: -webkit-center;

          .next-btn {
            background: linear-gradient(180deg, #b924b4, #8525b7) !important;
            border: none;
            //margin-left: 110px;

            i {
              margin-left: 15px;
              padding: 8px;
            }
          }
        }
      }
    }
  }
}

.profile-details * {
  color: #454847;
}

.sidepanel-details .side_penel_links .heading_text {
  font: normal normal 900 24px/28px "Avenir";
  letter-spacing: 0px;
  color: #454847;
}

.sidepanel-details .side_penel_links .subtitle {
  font: normal normal medium 16px/22px "Avenir";
  letter-spacing: 0px;
  color: #575757;
}

@media (max-width: 1500px) {
  .profile-details .main-wrp-page .left-side-content .content-heading h3 {
    margin-left: 0;
  }

  .profile-details .personalize-profile-section {
    padding-left: 0px !important;
  }

  .profile-details .personalize-profile-section .profile {
    margin-left: 0px !important;
  }

  .profile-details .personalize-profile-section .personalize-section {
    margin-left: 0px !important;
  }

  .profile-details .personalize-profile-section .preview_section .prev-heading {
    margin: 15px 0 10px 0px !important;
  }

  .profile-details
    .personalize-profile-section
    .preview_section
    .bee-bussiness {
    margin-left: 0;
  }

  .profile-details
    .personalize-profile-section
    .preview_section
    .bee-bussiness
    .mobile-div
    .phone-img-2 {
    width: 63%;
  }
}

.bee-bussiness .mobile-div {
  position: relative;
  width: 100%;
  text-align: center;
}

.bee-bussiness .mobile-div .phone-under-div {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.bee-bussiness .mobile-div .phone-img-2 {
  width: 70%;
  position: absolute;
  bottom: 0;
  left: 15%;
  border-radius: 0 0 15px 15px;
}

@media (max-width: 1450px) {
  .card-prize__container {
    padding: 0 calc(0.1em + 1vw);
    min-width: 65%;
  }
  .silver-card__item {
    display: flex;
    width: 600px !important;
    margin-left: 30%;
  }

  .level-bronce {
    min-width: 35%;
  }
}
@media (max-width: 1200px) {
  .card-prize__container {
    padding: 0 calc(0.1em + 1vw);
    min-width: 100%;
  }

  .silver-card__item {
    display: flex;
    width: 700px !important;
    justify-content: space-between;
    margin: 0;
  }
  .level-bronce {
    min-width: 350px;
  }
}
@media (max-width: 991px) {
  .left-side-content {
    width: calc(100% - 300px) !important;
  }
}

@media (max-width: 890px) {
  .card-prize__container {
    div div div {
      padding: 0;
      gap: 1em;
      margin: 0;
    }
  }
}

@media (max-width: 767px) {
  .profile-details .main-wrp-page {
    flex-direction: column;
    align-items: center;
  }

  .profile-details .main-wrp-page .left-side-content {
    width: 100% !important;
  }

  .card-prize__container {
    div div div {
      align-items: center;
      justify-content: space-between;
    }
  }
}
@media (max-width: 575px) {
  .card-prize__container {
    div div div {
      width: auto;
    }
  }

  .silver-card__item {
    width: 100% !important;
    flex-flow: wrap column;
  }
}

@media (max-width: 515px) {
  .card-prize__container {
    div div div {
      justify-content: flex-start;
    }
  }
}
