body {
    background: #F7F7F7 !important;
}

.card {
    margin-bottom: 24px;
    box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
    background: #F7F7F7;
}

.brand-name {
    width: 100%;
    height: 100%;
}

.brand-name img {
    width: 90px;
    height: 100%;
}

.brand-name {
    background: transparent;
    padding: 24px;
}

.login-btn {
    background: linear-gradient(180deg, #B924B4, #8525B7) !important;
    color: #fff !important;
    border: none;
}


.account-pages {
    .login-title {
        font-size: 20px;
        font-weight: 900;
        margin-left: 25px;
    }
}

.input-control{
    display: inline-block;
    width: 100% !important;
    display: block;
    padding: 0.47rem 0.75rem;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}