.finish-up-page {
  background-color: #eca109;
  background-image: url(../../../images//layouts//finish-up.png);
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: 75%;
  background-repeat: no-repeat;
  padding: 0 60px;
  position: relative;
  z-index: 999999;
  border: 1px solid #707070;

  .logo-div {
    padding-top: 60px;
  }

  .main-heading {
    p {
      margin-top: 30px;
      font: normal normal medium 48px/56px "Avenir" !important;
      font-size: 48px;
      letter-spacing: -1.16px;
      color: #ffffff;
      text-shadow: 0px 3px 6px #00000029;
    }
  }

  .main-head-details {
    margin: 0;
    color: #fff;
    font-size: 45px;
    font: normal normal 900 80px/88px "Avenir";
    letter-spacing: -1.93px;
    color: #ffffff;
    text-shadow: 0px 3px 6px #00000029;
  }

  .continuar-button {
    margin-top: 30px;

    button {
      font-size: 16px !important;
      color: #fff !important;
      background: linear-gradient(180deg, #b924b4, #8525b7) !important;
      box-shadow: none;
      border: 0;
      height: 53px;
      width: 154px;
    }
  }
}

// .finish-up-page::after {
//     content: "";
//     position: absolute;
//     background-image: url(../../../images//layouts//finish-up.png);
//     background-repeat: no-repeat;
//     width: 100%;
//     height: 93%;
//     top: 42px;
//     bottom: 0;
//     background-size: contain;
//     text-align: center;
//     left: 67%;
//     transform: translatex(-50%);
//     z-index: -1;
// }

// .logo-div {
//     padding-top: 60px;
// }

// .main-heading p {
//     font-size: 40px;
//     line-height: 57px;
//     color: #fff;
//     margin-top: 30px;
// }

// .main-head-details {
//     margin: 0;
//     color: #fff;
//     font-size: 45px;
// }

// .continue-btn {
//     font-size: 16px !important;
//     color: #fff !important;
//     background: linear-gradient(180deg, #B924B4, #8525B7) !important;
//     border-color: none;
//     box-shadow: 0 0 0 0.15rem rgba(111, 132, 234, 0.5);
//     height: 53px;
//     width: 154px;
// }

// .continuar-button {
//     margin-top: 30px;
// }

// .main-img {
//     width: 100%;
// }

@media (max-width: 1236px) {
  .main-head-details {
    font-size: 35px !important;
  }

  .main-heading p {
    font-size: 38px !important;
  }
}

@media (max-width: 1013px) {
  .main-head-details {
    font-size: 25px !important;
  }

  .main-heading p {
    font-size: 25px !important;
  }

  .continuar-button button {
    width: 125px;
    height: 43px;
    font-size: 13px !important;
  }

  .main-heading p {
    margin-top: 7px;
    margin-bottom: 0;
  }

  .logo-div {
    padding-top: 20px !important;
  }
}

@media (max-width: 767px) {
  .finish-up-page {
    background-position: bottom !important;
  }
}

@media (max-width: 575px) {
  .finish-up-page {
    padding-left: 10px;
    padding-right: 10px;
  }

  .logo-div {
    text-align: center !important;
  }
}
