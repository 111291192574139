:root {
  margin: 0 !important;
  padding: 0 !important;
}

.container-prize {
  background-color: #feefef;
  width: 100%;
}

.title {
  color: #404757;
  font-size: 48px;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
}

.subtitle {
  color: #404757;
  font-size: 32px ;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}

.description {
  color: #404757;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  margin-top: 8px;
}


.button-how-work {
  background: transparent linear-gradient(180deg, #1186FA 0%, #1166FB 100%) 0% 0% no-repeat padding-box;
  color: white;
  border: none !important;
  border-radius: 30px !important;
  cursor: pointer;
  font-family: "Poppins";
  font-weight: 600 !important ;
  font-size: 20px !important;
  height: 60px;
}

.left-side {
  background-color: #fff;
  border-top-right-radius: 83px;
  border-bottom-right-radius: 83px;
  margin-left: 0;
}

.button-continue-prizestup {
  background: transparent linear-gradient(180deg, #1186FA 0%, #1166FB 100%) 0% 0% no-repeat padding-box;
  color: white;
  border: none !important;
  border-radius: 30px !important;
  cursor: pointer;
  font-family: Poppins;
  font-size: 18px !important;
  height: 60px;
}

.option-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.button-continue-prizestup:disabled {
  background-image: linear-gradient(to top, #64626A, #64626A) !important;
  color: #fff
}
.button-generic-prize {
  background: transparent linear-gradient(180deg, #1186FA 0%, #1166FB 100%) 0% 0% no-repeat padding-box;
  color: white;
  border: none !important;
  border-radius: 30px !important;
  cursor: pointer;
  font-family: Poppins;
  font-size: 18px !important;
  height: 60px;
}
.button-generic-prize:disabled {
  background-image: linear-gradient(to top, #64626A, #64626A) !important;
  color: #fff
}

.item-prize {
  border: 1px solid #858a96;
  color: #858a96;
  border-radius: 20px;
  font-size: 16px;
  font-weight: bold;
  font-family: "Open Sans";
  padding: 4px 8px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.option-name {
  margin: 0;
}

.info {
  color: #454847 !important;
}

.input-add-options {
  border: 1px solid #858a96;
  border-radius: 10px !important;
}

.add-button {
  background: linear-gradient(180deg, #b924b4, #8525b7);
  color: white;
  border: none !important;
  border-radius: 10px !important;
  cursor: pointer;
  font-family: "Open Sans" !important;
  font-size: 16px !important;
}

.register-back-button {
  color: #404757;
  border: none;
  text-decoration: underline;
  background-color: transparent;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: bold !important;
}

.profile-details-seal {
  background-color: #fef2ef !important;
}

.input-amount {
  display: inline-flex; /* Permite que el contenedor se ajuste al contenido */
  align-items: center;
  background-color: #fff !important;
  border: solid 1px #1177fa !important;
  border-radius: 10px !important;
  padding: 0.5rem; /* Espaciado interno */
}

.card-bronce {
  background: linear-gradient(180deg, #b924b4, #8525b7);
}

.card-silver {
  background: linear-gradient(180deg, #ff0080, #f6007b);
}

.card-gold {
  background: linear-gradient(180deg, #eca109, #ed8e09);
}

.checkbox-container {
  position: relative;
  margin: 0;
  padding: 0 10px;
}

.checkbox-container input {
  opacity: 0;
  width: 0;
  height: 0;
}

.checkbox-container input:checked ~ .checkmark {
  background-color: #1177fa !important;
  border: none
}

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: solid 1px #858A96;
  border-radius: 50%;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 7px;
  top: 3px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.add-option-continer {
  display: grid;
  grid-template-columns: 200px 100px;
  gap: 8px;
}

@media screen and (max-width: 768px) {
  .profile-details-seal {
    background-color: #000 !important;
  }

  .title {
    color: #fff;
    font: bold 24px Poppins;
  }

  .subtitle {
    color: #999999;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }

  .description {
    color: #404757;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
  }

  .description-question {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    margin-block: 16px;
  }

  .text-choose {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 400;
  }

  .text-999 {
    color: #999 !important;
  }

  .container-prize {
    background-color: #000;
    width: 100%;
  }


  .add-button {
    background: linear-gradient(180deg, #b924b4, #8525b7);
    color: white;
    border: none !important;
    border-radius: 10px !important;
    cursor: pointer;
    font-family: "Open Sans" !important;
    font-size: 12px !important;
  }

  .button-continue {
    background-color: #1186fa !important;
    color: white;
    border: none !important;
    border-radius: 30px !important;
    cursor: pointer;
    font-family: Poppins;
    font-size: 18px !important;
    height: 50px;
  }

  .button-continue:disabled {
    background-color: #999999 !important;
    color: white;
    border: none !important;
    border-radius: 30px !important;
    cursor: pointer;
    font-family: Poppins;
    font-size: 18px !important;
    height: 50px;
  }

  .register-back-button {
    color: #000 !important;
    border: none;
    text-decoration: underline;
    background-color: transparent;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: bold;
  }

  .option-container {
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }

  .option-label {
    padding: 6px 10px;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 300;
    margin: 0;
  }

  .custom-option-container {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 8px;
  }
}
