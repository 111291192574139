

.add-password-container {
    padding: 16px 16px;
    display: flex;
    flex-direction: column;
}

.add-password-label {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
}

.add-password-input {
    border-radius: 16px;
    border: 2px solid #999999;
    padding: 10px 16px;
}

.add-password-btn-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 36px;
}

.change-password-btn {
    border: none;
    background-color: #1186FA;
    padding: 8px 16px;
    border-radius: 8px;
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
}
.back-password-btn {
    border: none;
    background-color: transparent;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    text-decoration: underline;
}