.wlcm-page-two {
  height: 100vh;
}

.phone-img-wlcm {
  height: 100%;
}

.sub-title-wlcm {
  margin: 0;
  color: #fff;
  font-size: 32px;
  font: normal normal medium 48px/56px Avenir;
  letter-spacing: -1.16px;
  color: #ffffff;
  text-shadow: 0px 3px 6px #00000029;
}

.description-wlcm {
  margin-top: 0 !important;
  color: #fff;
  font-size: 20px;
  font: normal normal medium 48px/56px Avenir;
  letter-spacing: -1.16px;
  color: #ffffff;
  text-shadow: 0px 3px 6px #00000029;
}

.button {
  background-image: linear-gradient(to top, #b924b4, #b924b4) !important;
  color: white;
  border: none !important;
  border-radius: 30px !important;
  cursor: pointer;
  font-family: Poppins;
  font-size: 18px !important;
  height: 60px;
}

@media screen and (max-width: 768px) {
  .welcome-two-responsive {
    height: 100vh !important;
    background: transparent linear-gradient(180deg, #8525b7 0%, #b924b4 100%) 0%
      0% no-repeat padding-box;
  }

  .sub-title-wlcm-responsive {
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    color: #fff;
  }

  .button {
    background-image: linear-gradient(to top, #eca109, #ed8e09) !important;
    color: white;
    border: none !important;
    border-radius: 30px !important;
    cursor: pointer;
    font-family: Poppins;
    font-size: 16px !important;
    font-weight: 600 !important;
    height: 60px;
    padding-left: 3rem !important;
    padding-right: 3rem !important;
    z-index: 100;
  }

  .phone-img-wlcm {
    position: fixed;
    height: 70%;
    bottom: 0;
    z-index: 1;
  }
}
