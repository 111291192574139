
.add-email-text {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    text-align: center;
    padding: 8px 0;
}

.add-email-container {
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
}

.add-email-label {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
}

.add-input-email {
    border-radius: 16px;
    border: 2px solid #999999;
    padding: 10px 16px;
}

.add-btn-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 36px;
}

.send-code-btn {
    border: none;
    background-color: #1186FA;
    padding: 8px 16px;
    border-radius: 8px;
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
}

.back-btn {
    border: none;
    background-color: transparent;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    text-decoration: underline;
}

.back-btn a {
    color: #000000;
}