.card-form-fields {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap : 10px;
}

.card-name-field {
    grid-column: 1 / 3;
}

.card-full-name-field {
    grid-column: 1 / 3;
}

.security-logos {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
}

.lock-content {
    border-right: 2px solid #858a96;
    margin: 0;
}

.lock-item {
    width: 15px;
    margin: 0 9px 0 0;
}

.card-logos {
    display: flex;
    align-items: center;
    gap: 20px;
}

.card-logos img {
    width: 50%;
    object-fit: cover;
}

.card-logos img:nth-child(1) {
    width: 30%;
    object-fit: contain;
}