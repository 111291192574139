input.seal-input {
  border: none !important;
  border-radius: 0 !important;
  background-color: transparent !important;
  font-size: 64px;
  transition: width 0.2s ease; /* Suaviza la transición */
}

span.seal-suffix {
  margin-right: 0.5rem; /* Espaciado entre el símbolo de moneda y el input */
  font-size: 48px; /* Ajusta según el diseño */
  background-color: transparent;
  border: none;
}

.seal-img {
  width: 100%;
  height: 112%;
}

.seal-img img {
  width: 100%;
  height: 100%;
}

.next-btn {
  background: linear-gradient(180deg, #b924b4, #8525b7) !important;
  border: none;
  margin-left: 110px;
}

.next-btn i {
  margin-left: 15px;
  padding: 8px;
}

.line_section {
  border: 1px solid #70707047;
  margin-left: 40px;
}

.button-continue {
  background-color: #1186fa !important;
  color: white;
  border: none !important;
  border-radius: 30px !important;
  cursor: pointer;
  font-family: Poppins;
  font-size: 18px !important;
  height: 60px;
}

@media screen and (max-width: 768px) {


  input.seal-input {
    border: none !important;
    max-width: 15rem !important;
    border-radius: 0 !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    background-color: transparent !important;
    font-size: 24px;
  }

  span.seal-suffix {
    background-color: transparent !important;
    border: none !important;
    border-radius: 0 !important;
    font-size: 24px;
  }

  .span-description-amount {
    font-family: Poppins;
    font-size: 16px;
    color: #999999;
  }

  .span-description-over-card {
    font-size: 16px;
    color: #000;
  }

  .prize-text-amount {
    font-size: 12px;
    font-family: Poppins;
  }

  .button-continue {
    background-color: #1186fa !important;
    color: white;
    border: none !important;
    border-radius: 30px !important;
    cursor: pointer;
    font-family: Poppins;
    font-size: 18px !important;
    height: 50px;
  }

  .register-back-button {
    color: #000 !important;
    border: none;
    text-decoration: underline;
    background-color: transparent;
    font-family: "Open Sans";
    font-size: 16;
    font-weight: bold;
  }

  .option-checked {
    background-color: #1186fa !important;
    color: white;
    border: none !important;
  }
}
