.register-store-info-left {
  border-radius: 0 83px 83px 0;
  background-color: #ffffff;
  height: 90%;
}

.register-store-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.register-store-info-second {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 16px;
  color: #404757;
}
.register-store-info-title {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 40px;
  color: #404757;
}

.register-store-list-item {
  font-family: "Poppins";
  font-size: 16px;
  padding: 0.3rem 0;
}

.register-store-create-title {
  color: #404757;
  font-family: "Poppins";
  font-size: 24px;
  font-weight: bold;
}

.register-input-label {
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: bold;
  color: #404757;
}

.register-input {
  height: 3rem;
  border-radius: 1.75rem !important;
  border: 2px solid #707070 !important;
  font-size: 12px;
  font-family: "Open Sans";
}

.register-input::placeholder {
  color: #858a96;
  font-size: 12px;
  font-family: "Open Sans";
  font-weight: bold;
}

.register-back-button {
  color: #404757;
  border: none;
  text-decoration: underline;
  background-color: transparent;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: bold;
}

.regitser-country-selector {
  width: 2rem !important;
  height: 2rem !important;
  position: relative;
  top: -10px;
  left: -15px;
}

.register-country-card-selected {
  border: 2px solid #1186fa !important;
}

.regitser-country-selector:checked {
    background-color: #1186FA !important;
    border: none !important
}

.register-question {
  font-family: "Poppins";
  font-size: 24px;
  color: #404757;
  font-weight: 300;
}
.register-other-country {
  font-family: "Poppins";
  font-size: 16px;
  text-decoration: underline;
  color: #404757;
}

.regitser-country-selector::content {
  content: "\2713";
  text-align: center;
  font-size: 20px;
  line-height: 20px;
  color: red;
}

.people-img {
  width: 100%;
  top: 1.5rem;
  left: -2rem;
}

.manager-img {
  width: 100%;
}

.payment-form-tyc {
  font-family: "Open Sans";
  font-size: 16px;
  color: #858a96;
}

.country-flag {
  width: 4rem;
  max-height: 3.5rem;
}

.country-text {
  font-weight: bold;
  font-family: Poppins;
  font-size: 20px;
}

.register-buttom-first {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  color: #FFFFFF;
  border: none;
  border-radius: 52px;
  background-color: #858A96;
  width: 190px;
  height: 56px;
}

.register-buttom-first:enabled {
  background: transparent linear-gradient(180deg, #1186FA 0%, #1166FB 100%) 0% 0% no-repeat padding-box;
  
}

.register-buttom-second {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  color: #FFFFFF;
  border: none;
  border-radius: 52px;
  background-color: #858A96;
  width: 190px;
  height: 60px;
}

.register-buttom-second:enabled {
  background: transparent linear-gradient(180deg, #1186FA 0%, #1166FB 100%) 0% 0% no-repeat padding-box;
}

.register-buttom-third {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  color: #FFFFFF;
  border: none;
  border-radius: 52px;
  background-color: #858A96;
  width: 190px;
  height: 60px;
}

.register-buttom-third:enabled {
  background: transparent linear-gradient(180deg, #1186FA 0%, #1166FB 100%) 0% 0% no-repeat padding-box;
}

@media screen and (max-width: 768px) {
  .register-store-info-left {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background-color: #ffffff;
    height: 100%;
  }

  .register-back-button {
    width: 100%;
    padding: 1rem 0;
  }

  .people-img {
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
  }

  .register-store-info-title {
    text-align: center;
  }

  .register-question {
    font-family: "Poppins";
    font-size: 12px;
    color: #999999;
    font-weight: 300;
  }

  .regitser-country-selector {
    width: 1.2rem !important;
    height: 1.2rem !important;
    position: relative;
    top: -15px;
    left: -12px;
  }

  .regitser-country-selector:checked {
    background-color: #1186FA !important;
    border: none !important
}

  .country-flag {
    width: 3.5rem;
    max-height: 2rem;
  }

  .country-text {
    font-weight: normal;
    font-size: 12px;
  }

  .register-other-country {
    color: #fff;
  }

  .register-store-create-title {
    text-align: center;
    color: #000;
  }

  .register-buttom-first {
    font-size: 22px;
    width: 270px;
    height: 55px;
  }

  .register-buttom-second {
    font-size: 18px;
    width: 250px;
    height: 50px;
  }

  .register-buttom-third {
    font-size: 18px;
    width: 250px;
    height: 50px;
  }
  
}
