.title-profile-details {
  font-size: 24px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  color: #404757;
}

.subtitle-profile-details {
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  color: #404757;
}

.margin-row {
  margin-left: 0 !important;
}

.img-content-profile-details {
  height: 150px !important;
  width: 150px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px !important;
  overflow: hidden;
  
}

.img-content-profile-details img {
  height: 130px !important;
  width: 130px !important;
  border-radius: 15px !important;
}

.url-logo {
  box-shadow: 0px 3px 6px #00000029;

}

.phone-img {
  width: 500px;
  height: 1100px;
}

.phone-img-img {
  width: 8rem;
  height: 8rem;
}
.phone-img-img img {
  width: 8rem;
  height: auto;
  max-width: 50%;
  border-radius: 15px;
}

.gradiant-button {
  background: linear-gradient(180deg, #b924b4, #8525b7);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 30px !important;
  border: none !important;
  cursor: pointer;
  font-family: Poppins;
  font-size: 18px !important;
  height: 60px;
}

.mobile-div {
  position: relative;
  display: flex;
  justify-content: center;
}

.phone-under-div {
  position: absolute;
  top: 70px;
  left: 60px;
  display: flex;
  align-items: center;
  width: 22.5rem;
}

.phone-under-div-rest {
  position: absolute;
  top: 210px;
  left: 45px;
  display: flex;
  align-items: center;
  width: 22.5rem;
}

.phone-img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.back-link {
  font-size: 20px;
  color: #0075f0;
}

.profile-details {
  position: absolute;
  top: 0;
  left: 40;
}

.phone-details-heading {
  font-size: 24px;
  font-weight: bold;
  width: 130%;
  margin: 0;
}

.phone-details-heading span {
  font-weight: 800;
}

.phone-details-miam {
  font-size: 16px;
  color: #ffa207;
  align-items: flex-start;
  margin-left: 0;
}

.phone-details-low {
  font-size: 16px;
  color: #8f8f93;
  margin: 0;
}

.phone-button {
  font-size: 16px;
  color: #fff;
  text-align: center;
  background: #1186fa;
  border: none;
  border-radius: 10px;
}

.delete-button {
  position: absolute;
  top: 0;
  left: 131px;
  background-color: #d8dbdb;
  height: 30px !important;
  width: 30px !important;
  border-radius: 15px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.store-title {
  font-size: 20px !important;
  font-family: "Open Sans", sans-serif;
}

.change-logo {
  font-weight: 700 !important;
  color: #1186fa;
}

.change-logo:hover {
  text-decoration: underline !important;
}

.bottom-movil-detail {
  min-height: 20rem !important;
  background-color: #ffffff !important;
}

.container-bottom {
  background-color: #fff;
  margin-top: 40px;
  border-top-left-radius: 40px !important;
  border-top-right-radius: 40px !important;
}

@media screen and (max-width: 768px) {
  .title-profile-details {
    font-size: 24px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: #fff;
  }

  .subtitle-profile-details {
    font-size: 12px;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    color: #999999;
  }

  .img-content-profile-details {
    align-items: center;
    justify-content: end;
    width: 120px !important;
    height: 120px !important;
  }

  .delete-button {
    position: absolute;
    top: -10px;
    left: 248px;
    background-color: #d8dbdb;
    height: 30px !important;
    width: 30px !important;
    border-radius: 15px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobile-div {
    position: relative;
    display: flex;
    justify-content: center;
  }

  .phone-img {
    position: absolute;
    width: 400px;
    height: 750px;
    top: 50px;

  }

  .phone-under-div {
    position: absolute;
    top: 5% !important;
    left: 16% !important;
    display: flex;
    align-items: center;
    width: 22.5rem;
  }

  .back-link {
    font-size: 12px;
    color: #0075f0;
  }

  .phone-img-img {
    width: 6rem;
    height: 6rem;
  }
  .phone-img-img img {
    width: 6rem;
    height: auto;
    max-width: 50%;
    border-radius: 15px;
  }

  .profile-details {
    position: absolute;
    top: 0;
    left: 40;
  }

  .phone-details-heading {
    font-size: 16px;
    font-weight: bold;
    width: 130%;
    margin: 0;
  }

  .phone-details-heading span {
    font-weight: 800;
  }

  .phone-details-miam {
    font-size: 10px;
    color: #ffa207;
    align-items: flex-start;
    margin-left: 0;
  }

  .phone-details-low {
    font-size: 10px;
    color: #8f8f93;
    margin: 0;
  }

  .phone-button {
    font-size: 10px;
    color: #fff;
    text-align: center;
    background: #1186fa;
    border: none;
    border-radius: 10px;
  }

  .phone-under-div-rest {
    position: absolute;
    top: 23%;
    left: 18%;
    display: flex;
    align-items: center;
    width: 15rem;
  }
}
