.login-container {
    background-color: #FFFFFF;
    height: 100vh;
}

.info-login-container {
    background-color: #EEF2F6;
    padding: 56px 0;
    position: relative;
}

.info-login-text {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.welcome-login {
    font-family: 'Poppins', sans-serif;
    font-size: 32px;
    font-weight: 800;
}

.instructions-login {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
}

.name-business {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
}

.logo-bee-login-container{
    display: flex;
    justify-content: center;
}

.logo-bee-login {
    position: absolute;
    width: 100px;
}

.login-form-container {
    padding: 48px  24px 0 24px;
}

.login-inputs-container {
    display: flex;
    flex-direction: column;
    margin: 16px 0;
}

.login-inputs-container label {
    font-family: 'Roboto', sans-serif;
}

.login-inputs-container input {
    border-radius: 16px;
    border: 1px solid #999999;
    padding: 10px 16px;
}

.login-inputs-container-remember {
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin: 16px 0;
}

.login-btn-container {
    display: flex;
    justify-content: center;
    padding: 16px 0;
}

.login-btn-access {
    width: 80%;
    background-color: #1186FA;
    border: 0;
    color: #FFFFFF;
    padding: 10px 0;
    border-radius: 16px;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
}