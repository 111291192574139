.wlcm-page-one {
  background: #eca109;
  height: 100vh;

  .wlcm-wrp {
    padding-top: 30px;

    .point-section {
      margin-top: 30px;

      ul {
        margin-left: 25px;
        padding: 0;

        li {
          list-style: auto;
          font-size: 22px;
          color: #fff;
        }
      }
    }

    .title {
      p {
        font-size: 60px;
        line-height: 57px;
        color: #fff;
        margin-top: 30px;
      }
    }

    .sub-title {
      p {
        margin: 0;
        color: #fff;
        font-size: 40px;
      }
    }

    .next-btn {
      position: absolute;
      bottom: 2px;
      right: 100px;
      color: transparent;
      background: transparent;
      border: none;
      font-size: 25px;
    }
  }

  .side-img {
    text-align: center;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }

    .next-btn {
      position: absolute;
      bottom: 2px;
      right: 100px;
      color: transparent;
      background: transparent;
      border: none;
      font-size: 18px;
    }
  }
}

@media (max-width: 1199px) {
  .wlcm-page-one .side-img img {
    height: auto;
  }

  .wlcm-page-one .side-img .next-btn {
    position: fixed;
    bottom: 50px;
    right: 50px;
  }
}

@media (max-width: 991px) {
  .wlcm-page-one .wlcm-wrp .title p {
    font-size: 40px;
  }

  .wlcm-page-one .wlcm-wrp .sub-title p {
    font-size: 18px;
  }

  .wlcm-page-one .wlcm-wrp .point-section ul li {
    font-size: 14px;
  }

  .wlcm-page-one .side-img img {
    object-fit: cover;
  }
}

@media (max-width: 767px) {
  .wlcm-page-one {
    height: 100% !important;
  }

  .wlcm-page-one .side-img img {
    object-fit: cover;
    height: 100% !important;
  }

  .side-img {
    position: absolute;
    bottom: 0;
  }

  .wlcm-page-one {
    height: 100vh !important;
  }
}

@media (max-width: 575px) {
  .side-img {
    position: inherit !important;
    bottom: 0;
  }

  .wlcm-page-one {
    height: 100% !important;
  }

  .wlcm-page-one .wlcm-wrp .title p {
    font-size: 28px;
    line-height: 38px;
    margin-top: 15px;
    margin-bottom: 0;
  }

  .wlcm-page-one .wlcm-wrp .point-section {
    margin-top: 10px;
  }

  .wlcm-page-one .wlcm-one-wrp .btn-div button {
    margin-top: 15px;
  }

  .wlcm-page-one .side-img .next-btn {
    bottom: 0;
    right: 0;
  }
}
