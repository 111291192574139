.password-recovery-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #EEF2F6;
    padding: 48px 0;
    position: relative;
    margin-bottom: 56px;
}

.password-recovery-title {
    font-family: 'Poppins', sans-serif;
    font-size: 28px;
    font-weight: 800;
}

.password-recovery-subtitle {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    width: 70%;
}

.logo-bee-forgot-password {
    width: 90px;
    height: 90px;
    position: absolute;
    bottom: -45px;
    left: 40%;;
}